@font-face {
  font-family: 'Inter';
  font-display: block;
  src: url('/fonts/inter.woff2') format('woff2');
  font-weight: 100 900;
}

/* Inter font */
html {
  font-family: 'Inter', var(--sans-serif);
}

:root {
  --blue: #2b737f;
  --light-blue: rgba(175, 217, 224, 0.6);
  --lighter-blue: rgba(175, 217, 224, 0.1);
  --dark-blue: #21362c;

  --white: whitesmoke;
}

* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
}

body {
  min-height: 100vh;
  font-family: 'Inter';
  font-size: 16px;
  background-color: whitesmoke;
}

[data-active='1'] nav div:nth-child(2) a::before {
  display: none;
}
[data-active='2'] nav div:nth-child(3) a::before {
  display: none;
}
[data-active='3'] nav div:nth-child(4) a::before {
  display: none;
}
[data-active='4'] nav div:nth-child(5) a::before {
  display: none;
}
[data-active='5'] nav div:nth-child(6) a::before {
  display: none;
}

.navigation {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 2;
}

nav {
  background-color: whitesmoke;
  padding: 30px 10%;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  text-transform: capitalize;
}

nav.dark {
  background-color: var(--blue);
  color: #efefef;
}

nav.dark .burger svg path {
  stroke: #efefef;
}

nav div {
  font-weight: 600;
  font-size: 1.2rem;
  margin: 0 20px;
}

nav .burger {
  display: none;
}

.mobile {
  display: none;

  position: fixed;
  top: 70px;

  height: calc(100vh - 70px);
  width: 100vw;
  background-color: var(--blue);

  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mobile div {
  font-size: 1.5rem;
  line-height: 2;
  background-color: var(--blue);
  color: #efefef;
}

.mobile.show {
  display: flex;
}

section {
  padding: 5% 3%;
  background-color: whitesmoke;
  color: var(--dark-blue);
}

.dark {
  background-color: var(--blue);
  color: #efefef;
}

.title {
  font-weight: bold;
  font-size: 1.5rem;
  position: sticky;
  position: -webkit-sticky;
  top: 16px;
  width: 50%;
  padding: 10px;
  border-radius: 10px;
  z-index: 3;
}

article {
  margin: 5rem auto 0;
  max-width: 42rem;
  font-size: 1.3rem;
  line-height: 1.7;
}

h1 {
  font-size: 3rem;
  line-height: 1.2;
}

p {
  margin: 2rem 0;
  text-indent: 1.5rem;
  letter-spacing: -0.36px;
  font-feature-settings: 'frac' 1;
}

a {
  outline: none;
  text-decoration: none;
  color: inherit;
  position: relative;
  z-index: 1;
  white-space: nowrap;
}

a::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 8px;
  background-color: var(--light-blue);
  z-index: -1;
  transition: height 150ms ease-in-out;
}

a:hover::before,
a:focus::before {
  height: 100%;
  transition: height 150ms ease-in-out;
}

.dark a::before {
  background-color: var(--light-blue);
}

div.img {
  padding: 5% 3% 10rem 3%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

img {
  border-radius: 10px;
  width: 500px;
  max-width: 100%;
  height: auto;
  box-shadow: 0 30px 100px 5px rgba(0, 0, 0, 0.2);
}

.border img {
  box-shadow: 0 30px 100px 5px rgba(0, 0, 0, 0.2);
}

#googlemap {
  width: 100%;
}

li {
  margin: 1.5rem 0;
}

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

table tr td:first-child {
  white-space: nowrap;
}

table th,
table td {
  text-align: left;
}

table tbody tr {
  height: 5rem;
  border-bottom: 3px solid var(--light-blue);
}

table thead tr {
  height: 5rem;
}

table tbody tr:last-child {
  border: none;
}

th:nth-child(3),
th:nth-child(2),
td:nth-child(2),
td:nth-child(3) {
  text-align: right;
}

section .blog-link {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 1rem 1.5rem;
  margin-bottom: 3rem;
  background-color: transparent;
  transition: transform 300ms ease-in-out, box-shadow 300ms ease-in-out, background 300ms ease-in-out;
}

.dark .blog-link {
  border: 1px solid var(--light-blue);
}

section .blog-link:hover {
  background-color: rgba(0, 0, 0, 0.04);
  transform: translateY(-5px);
  box-shadow: 0 30px 100px 5px rgba(0, 0, 0, 0.05);
  transition: transform 300ms ease-in-out, box-shadow 300ms ease-in-out, background 300ms ease-in-out;
}

.dark .blog-link:hover {
  background-color: var(--lighter-blue);
}

.blog-link h2 {
  margin: 0;
}

.dark .blog-link span {
  color: var(--light-blue);
}

section .blog-link span {
  color: rgba(0, 0, 0, 0.5);
}

hr {
  height: 2rem;
  border: none;
}

.blog-posts a {
  white-space: normal;
}

.blog-posts a::before {
  display: none;
}

.banner {
  background: var(--lighter-blue);
  font-size: 16px;
  border: 1px solid var(--light-blue);
  padding: 0.5rem 1rem;
  border-radius: 6px;
  display: flex;
  align-items: center;
  max-width: 100%;
  white-space: normal;
  transition: transform 120ms ease;
}

.banner:hover {
  transform: translateY(-2px);
}

.banner::before {
  content: none;
}

@media (max-width: 750px) {
  .title {
    z-index: 1;
    position: relative;
  }

  nav {
    height: 70px;
    padding: 10px;
    justify-content: space-between;
  }

  nav div {
    display: none;
  }

  nav {
    justify-content: flex-end;
  }

  nav .burger {
    display: flex;
  }

  nav div {
    font-size: 1rem;
  }

  section {
    padding: 5% 10%;
  }

  table thead {
    display: none;
  }

  table tbody tr {
    display: block;
    height: unset;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
  }

  table tbody td {
    display: block;
    text-align: right;
  }

  table tbody td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
  }
}
